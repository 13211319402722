<template>
  <div class="container">
    <div
      class="margin-footer-v row flex-center-space-between container--full divider"
    >
      <img
        class="footer-form resp-display"
        src="../assets/images/svgs/form_footer.svg"
      />
      <ul class="padding-none">
        <img
          class="margin-bottom-footer--logo footer-logo"
          src="../assets/images/logo.jpg"
        />
        <li class="text--footer">VG Elektrotechnik GmbH</li>
        <li class="text--footer">
          Gewerbestraße 16,<br />
          79112 Freiburg im Breisgau
        </li>
        <li class="margin-bottom-footer">
          <a
            @click="setDataLayer('Lábléc', 'email_gombra_kattintas', '')"
            class="text--footer"
            href="mailto:info@vg-elektrotechnik.de"
            >info@vg-elektrotechnik.de</a
          >
        </li>
        <li class="margin-bottom-footer">
          <a
            @click="setDataLayer('Lábléc', 'telefonszam_gombra_kattintas', '')"
            class="text--footer"
            href="tel:0049-0-174-2679151"
            >+49 (0) 174 2679151</a
          >
        </li>
        <li class="margin-bottom-footer">
          <a
            @click="
              setDataLayer('Lábléc', 'Kattintás', 'www.vgelektrotechnik.com')
            "
            class="text--footer"
            href="/"
            >www.vg-elektrotechnik.com</a
          >
        </li>
        <li class="margin-bottom-footer">
          <a
            @click="
              setDataLayer('Lábléc', 'Kattintás', 'Adatvédelmi tájékoztató')
            "
            class="text--footer"
            href="/de/datenschutzerklarung"
            >Datenschutzerklärung</a
          >
        </li>
        <li class="margin-bottom-footer">
          <a
            @click="
              setDataLayer('Lábléc', 'Kattintás', 'Adatvédelmi tájékoztató')
            "
            class="text--footer"
            href="/de/impressum"
            >Impressum</a
          >
        </li>
        <li class="margin-bottom-footer">
          <a
            id="CookieBannerSettings"
            @click="openCookieSettings(true)"
            class="text--footer"
            >Cookie-Einstellungen</a
          >
        </li>
      </ul>
      <div
        class="divider--footer center resp-display--none text--menu margin-lr-auto"
      ></div>
      <div
        class="divider--footer center resp-display--none text--menu margin-lr-auto"
      ></div>
      <ul class="padding-none">
        <li class="title--footer margin-bottom-footer text">Navigation</li>
        <li class="margin-bottom-footer">
          <a class="text--footer" href="/">Startseite</a>
        </li>
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">About us</a>-->
          <router-link
            @click.native="setDataLayer('Lábléc', 'rolunk', 'rolunk')"
            class="text--footer nav-item"
            :to="
              '/' +
              $store.state.languageCode +
              '/' +
              urlSlug(l('main_menu_item_3'))
            "
            >{{ l("main_menu_item_3") }}
          </router-link>
        </li>
        <!--        <li class="margin-bottom-footer">
          &lt;!&ndash;          <a class="text&#45;&#45;footer" href="/">FAQ</a>&ndash;&gt;
          <router-link
              @click.native="setDataLayer('Lábléc', 'GYIK', 'GYIK')"
              class="text&#45;&#45;menu nav-item"
              :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_4'))
                  "
          >{{ l("main_menu_item_4") }}
          </router-link>
        </li>-->
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">Contact</a>-->
          <router-link
            @click.native="setDataLayer('Lábléc', 'kapcsolat', 'kapcsolat')"
            class="text--footer nav-item"
            :to="
              '/' +
              $store.state.languageCode +
              '/' +
              urlSlug(l('main_menu_item_5'))
            "
            >{{ l("main_menu_item_5") }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="flex-row-resp flex-center-space-between margin--medium-bottom">
      <p class="text--footer">Copyright © 2025 VG Elektrotechnik GmbH.</p>
      <img src="../assets/images/svgs/footerACGlogo.svg" alt="ACG logo" />
    </div>
    <go-top
      :radius="0"
      :boundary="1600"
      :right="30"
      :bottom="106"
      bg-color="transparent"
      src="@/assets/images/svgs/back-to-top.jpg"
      class="backToTop"
      >../assets/images/svgs/chevron-top-bright.svg
      <div class="backToTop"></div
    ></go-top>
  </div>
</template>

<script>
import GoTop from "@inotom/vue-go-top";

export default {
  props: {
    openCookieSettings: Function,
  },
  name: "Footer",
  components: {
    GoTop,
  },
  methods: {
    getProductFamilyHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.productFamily`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId,
        },
      }).href;
    },
  },
  computed: {
    productFamilies() {
      return this.$store.state.productFamilies.filter((pf) => !pf.hidden);
    },
  },
};
</script>
